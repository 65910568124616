import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

function Orderlist() {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const userdata = () => {
    axios.get(`${BASE_URL}/order`, { headers }).then((res) => {
      const responseData = res.data.data;
      //   const dataArray = Array.isArray(responseData)
      //     ? responseData
      //     : [responseData];
      //   setdata(dataArray);
      let dataArray = res.data.data;
      setdata([dataArray]);
      console.log(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    userdata();
  }, []);
  const getRowId = (row) => row._id;
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },

    // { field: "email", headerName: "Email", flex: 1 },
    // {
    //   field: "image",
    //   headerName: "image",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const { value } = params;
    //     return (
    //       <img
    //         src={`${BASE_URL}/${value}`}
    //         style={{
    //           width: "45px",
    //           height: "45px",
    //           borderRadius: "50%",
    //         }}
    //         className="img-fluid tableimgmui"
    //       ></img>
    //     );
    //   },
    // },
    // { field: "phone", headerName: "Phone", flex: 1 },
    // { field: "type", headerName: "Type", flex: 1 },
  ];

  const handleCheckboxChange = (e, id) => {
    setLoading(true);
    let formdata = {
      is_verified: e.target.checked,
      _id: id,
    };
    axios.put(`${BASE_URL}/user`, formdata, { headers }).then((res) => {
      console.log(res.data);

      userdata();
    });
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <RotatingLines
            strokeColor="#051322"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <section>
        <div className="container">
          <div className="row">
            <TitleComp title="Order List" />
            <div className="col-md-12">
              {data.length > 0 && (
                <Box sx={{ height: 500, width: "100%" }}>
                  <DataGrid
                    rows={data} // Update this line
                    columns={columns}
                    pageSize={5}
                    getRowId={getRowId}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  />
                </Box>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Orderlist;
