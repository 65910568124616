import React, { useEffect, useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import TitleComp from "../Component/TitleComp";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function Promolist() {
  const [loading, setLoading] = useState("");
  const [data, setdata] = useState([]);

  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const promolist = () => {
    axios.get(`${BASE_URL}/promos`, { headers }).then((res) => {
      setdata(res.data.data);
    });
  };

  useEffect(() => {
    promolist();
  }, []);

  const columns = [
    {
      feild: "val",
      headerName: "val",
      flex: 1,
    },
    {
      feild: "Code",
      headerName: "val",
      flex: 1,
    },
    {
      feild: "started_at",
      headerName: "Started At",
      flex: 1,
    },
    {
      feild: "ended_at",
      headerName: "Ended At",
      flex: 1,
    },
  ];

  const getRowId = (row) => row._id;

  return (
    <>
      <Sidebaar
        content={
          <>
            {loading && (
              <div className="overlay">
                <RotatingLines
                  strokeColor="#051322"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            )}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <TitleComp title="Promo List" />
                    <div className="col-md-12">
                      {data.length > 0 ? (
                        <Box sx={{ height: 500, width: "100%" }}>
                          <DataGrid
                            rows={data} // Update this line
                            columns={columns}
                            pageSize={5}
                            getRowId={getRowId}
                            initialState={{
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                          />
                        </Box>
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-center mt-3 ">
                            <h3 className="fw-bold">No Data Available</h3>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
}

export default Promolist;
