import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Audio, CirclesWithBar, RotatingLines } from "react-loader-spinner";
import Sidebaar from "../Component/Sidebaar";
import { Form } from "react-bootstrap";

function BeatStyle() {
  let token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idindex, setidindex] = useState("");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const userdata = () => {
    axios.get(`${BASE_URL}/beat_style`, { headers }).then((res) => {
      let dataArray = res.data.data;
      setdata(dataArray);
      console.log(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    userdata();
  }, []);

  const getRowId = (row) => row._id;
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => handleedit(e, index)}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </span>
        );
      },
    },
  ];

  const handleedit = (e, id) => {
    e.preventDefault();
    setidindex(id);

    setName(data[id].name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let requestdata = {
      name: name,
    };
    if (idindex) {
      requestdata._id = data[idindex]._id;
      axios
        .put(`${BASE_URL}/beat_style`, requestdata, { headers })
        .then((res) => {
          console.log(res);
          setLoading(false);
          userdata();
          setName("");
        });
    } else {
      axios
        .post(`${BASE_URL}/beat_style`, requestdata, { headers })
        .then((res) => {
          console.log(res);
          setLoading(false);
          userdata();
          setName("");
        });
    }
  };

  return (
    <Sidebaar
      content={
        <>
          <section>
            <div className="container">
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  {loading && (
                    <div className="overlay">
                      <RotatingLines
                        strokeColor="#051322"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                  )}
                  <TitleComp title="Add Beat Style" />
                  <div className="col-md-4">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="mt-4">
                      <button className="btn bg-blue text-white">Submit</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <TitleComp title="Beat Style List" />
                <div className="col-md-12">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      //   rows={data} // Update this line
                      rows={data.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                      columns={columns}
                      pageSize={5}
                      getRowId={getRowId}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}

export default BeatStyle;
