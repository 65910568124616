import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Form, FormGroup, Modal } from "react-bootstrap";

import axios from "axios";
import OtpInput from "react-otp-input";
import { BASE_URL } from "../BaseUrl";
import logo from "../img/logo.png";
import { toast, ToastContainer } from "react-toastify";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [otpbox, setotpbox] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = async (event) => {
    event.preventDefault();

    if (username == "9315809997" || username == "9667472768") {
      try {
        const response = await axios.post(`${BASE_URL}/login`, {
          phone: username,
        });
        setMessage(response.data.otp);

        toast.success(response.data.otp)
        setStatus(response.data.status);
        console.log(response.data.message);
        setotpbox(true);
        if (response.data.error === 201 || 200) {
          setIsClicked(true);
        }

      } catch (error) {
        console.error(error);
      }
    } else {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 1000)
    }
  };

  const handleOtpClick = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/verify_otp`, {
        phone: username,
        otp: otp,
      });

      if (response.status === 200) {
        if (Number(otp) === message) {
          if (response.data.type == "Admin") {

            navigate("/dash");
            // alert("navudf")
            console.log("match");
            console.log(response.data);
            console.log(response.data.token);
            const token = response.data.token;
            localStorage.setItem("token", token);
          } else {
            alert("You Can Not Login You are not admin");
          }
        } else {
        }
      } else {
        // Handle login error
        console.log(response);
        alert("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred while trying to login. Please try again later.");
    }
  };

  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setotpbox(false);
    }, 5000);
  }, [otpbox]);

  return (
    <>
      <ToastContainer />

      <section class="loginbg">
        <div class="container-fluid">
          <div
            class="align-items-center justify-content-center  row"
            style={{ height: "100vh" }}
          >
            <div class="col-md-8 ">
              <div
                class="align-items-center  shadow bg-white m-3 row"
                style={{ borderRadius: "30px", overflow: "30px" }}
              >
                <div class="loginImg col-md-6 p-0">
                  <img
                    alt="hey"
                    src={`https://img.freepik.com/free-photo/guitarist-playing-dark-nightclub-illuminated-by-spotlight-generated-by-artificial-intelligence_24640-131059.jpg?t=st=1700744333~exp=1700747933~hmac=a6fc52c4e9f82d9e79215eef7cd355ee9811c10e8bfd077a4f42eb290538675e&w=1060`}
                    class="img-fluid"
                    style={{ height: "500px" }}
                  />
                </div>
                <div class="col-md-6">
                  <div class="">
                    <div className="">
                      <h3 className="outletheading">
                        <img src={logo} alt="" />
                      </h3>
                      {isClicked ? (
                        <>
                          <Form
                            onSubmit={handleOtpClick}
                            className="login-form"
                          >
                            {/* <div className="d-flex align-items-center my-4 justify-content-center w-100">
                              <h1
                                className="text-center fw-normal mb-0 me-3"
                                style={{ color: "#1D5D9B" }}
                              >
                                Sign In
                              </h1>
                            </div> */}
                            <FormGroup className="mt-3 OtpBox">
                              <label className="mb-1 fw-bold" htmlFor="otp">
                                OTP
                              </label>
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder="OTP"
                                required
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            // onChange={handleOtp}
                            /> */}
                              <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                className="form-control "
                                renderSeparator={
                                  <span className="me-2"> </span>
                                }
                                renderInput={(props) => <input {...props} />}
                              />
                            </FormGroup>
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <button
                                  className="btn mt-3 auth_btn w-100"
                                  type="submit"
                                >
                                  Login
                                </button>
                              </div>
                              <div className="col-md-6" onClick={handleClick}>
                                <button className="btn bg-success mt-3 text-white w-100 text-center">
                                  Resend Otp
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      ) : (
                        <>
                          <Form onSubmit={handleClick} className="login-form">
                            {/* <div className="d-flex align-items-center my-4 justify-content-center w-100">
                              <h1
                                className="text-center fw-normal mb-0 me-3"
                                style={{ color: "#1D5D9B" }}
                              >
                                Sign In
                              </h1>
                            </div> */}
                            <FormGroup className="mt-3">
                              <label
                                className="mb-1 fw-bold mb-2"
                                htmlFor="name"
                              >
                                Phone
                              </label>
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                required
                                id="name"
                                value={username}
                                // onChange={handleChange}
                              /> */}

                              <input
                                type="text"
                                value={username}
                                required
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Use a regular expression to allow only numeric digits
                                  const numericInput = input.replace(/\D/g, "");
                                  // Limit the input to 10 characters
                                  const truncatedInput = numericInput.slice(
                                    0,
                                    10
                                  );
                                  setUsername(truncatedInput);
                                }}
                                name="phone"
                                id="phone"
                                className={`form-control`}
                              />
                            </FormGroup>
                            <button
                              className="btn mt-3 auth_btn w-100"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Form>
                        </>
                      )}

                      {message && otpbox && (
                        <Alert
                          variant="success"
                          className="text-center mt-2 fw-bold"
                        >
                          OTP sent to your phone number
                        </Alert>
                      )}
                      {/* {status && <p>Status: {status}</p>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Modal show={show} centered size="md" onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center">
            <h5 className="mb-0 text-danger">You cannot login. You are not an admin!</h5>

          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default LoginPage;
