import React, { useEffect, useState } from "react";
import Sidebaar from "./Sidebaar";
import sale from "../img/shopping.png";
import membership from "../img/exclusive.png";
import sell from "../img/sell.png";
import sellers from "../img/reseller.png";
import users from "../img/teamwork.png";
import totalbeat from "../img/totalbeat.png";
import cross from "../img/cancel.png";
import check from "../img/check.png";
import unverified from "../img/unverified-svgrepo-com.png";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { RotatingLines } from "react-loader-spinner";
import logo from "../img/logo.png";
import { useNavigate } from "react-router-dom";
import TotalMembership from "../Pages/TotalMembership";

function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Dashpage() {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const datagrid = [
    {
      name: "Total Sale",
      icon: sale,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.net_sales?.[0]?.totalNetAmount) ?? "0",
      url: "Totalsale",
    },
    {
      name: "Total Membership",
      icon: membership,
      // background:
      //   "radial-gradient( circle farthest-corner at 10% 20%,  rgba(176,229,208,1) 42%, rgba(92,202,238,0.41) 93.6% )",
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.membership_counts),
      url: `/Totalmembership`,
    },
    {
      name: "Total Beat Sold Out",
      icon: sell,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.beats_sold),
      url: "sold",
    },
    {
      name: "Number of Sellers",
      icon: sellers,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.no_of_sellers),
      url: "Totalseller",
    },
    {
      name: "Number of Buyer",
      icon: users,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.no_of_buyer),
      url: "TotalBuyer",
    },
    {
      name: "Total Available Beats",
      icon: totalbeat,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount:
        numberWithCommas(data?.no_of_beats) -
        numberWithCommas(data?.no_of_beats_rejected),
      url: "/fetchbeat",
    },
    {
      name: "Pending for Approval",
      icon: unverified,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.no_of_beats_pending),
      url: "pending",
    },
    {
      name: "Verified Beats",
      icon: check,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.no_of_beats_approved),
      url: "verified",
    },
    {
      name: "Rejected Beats",
      icon: cross,
      background:
        "radial-gradient(58.33% 62.5% at 50% 37.5%, rgb(78, 18, 70) 0%, rgb(30, 3, 42) 67.71%)",
      amount: numberWithCommas(data?.no_of_beats_rejected),
      url: "rejected",
    },
  ];

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // const handleDashboard = () => {
  //   setloading(true);
  //   axios.get(`${BASE_URL}/dashboard`, { headers }).then((res) => {
  //     setdata(res.data);
  //     console.log("res", res);
  //     if (res.data.message == "OK") {
  //       setloading(false);
  //     } else {
  //       setloading(false);
  //     }
  //   });
  // };

  const handleDashboard = () => {
    setloading(true);

    try {
      axios.get(`${BASE_URL}/dashboard`, { headers }).then((res) => {
        setdata(res.data);
        console.log("res", res);

        if (res.data.message === "OK") {
          setloading(false);
        } else {
          setloading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setloading(false);
      // Handle the error appropriately, e.g., show a user-friendly message
    }
  };
  const handleNavigation = (url, index) => {
    if (index == 1) {
      navigate(`/dash${url}`, { state: data?.memberships })
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    handleDashboard();
  }, []);

  return (
    <>
      {loading && (
        <div className="overlay">
          <RotatingLines
            strokeColor="#051322"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <section className="mt-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <div className="mb-3">
                <div
                  className="p-3 rounded-3"
                  style={{
                    background:
                      "radial-gradient(58.33% 62.5% at 50% 37.5%, #4E1246 0%, #1E032A 67.71%)",
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-3">
                      <div className="">
                        <img
                          src={logo}
                          className="img-fluid rounded-3"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <p
                        className="text-white jost fw-bold"
                        style={{ fontSize: "34px" }}
                      >
                        Admin Panel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row gy-3 ">
                {datagrid.map((item, index) => {
                  return (
                    <>
                      <div className="col-md-4 " key={index}>
                        <div
                          className="p-3 h-100 rounded-3"
                          onClick={() => handleNavigation(item.url, index)}
                          style={{ backgroundImage: `${item.background}` }}
                        >
                          <div className="">
                            <div className="row align-items-center">
                              <div className="col-md-3">
                                <div className="bg-white p-2 shadow rounded-3">
                                  <img
                                    src={item?.icon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <h3
                                  className="fw-bold jost text-white"
                                  style={{ fontSize: "18px" }}
                                >
                                  {item?.name}
                                </h3>
                                <h4 className="text-white ">{item?.amount}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className=" text-white rounded-3 p-2"
                    style={{
                      backgroundImage: ``,
                    }}
                  >
                    <h6 className="text-center text-light font-weight-bold mb-0">
                      Number of User
                    </h6>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashpage;
