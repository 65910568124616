import React from "react";
import Sidebaar from "../Component/Sidebaar";
import Orderlist from "../Element/Orderlist";

function OrderlistPage() {
  return (
    <>
      <Sidebaar content={<Orderlist />} />
    </>
  );
}

export default OrderlistPage;
