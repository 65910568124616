import React from "react";
import Sidebaar from "../Component/Sidebaar";
import Dashpage from "../Component/Dashpage";

function Dashboard() {
  return (
    <>
      <Sidebaar content={<Dashpage />} />
    </>
  );
}

export default Dashboard;
