import React, { useEffect, useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import { BASE_URL } from "../BaseUrl";
import convertDateToMonthName from "../Component/ConvertMonth";
import check from "../img/check.png";
import cancel from "../img/cancel.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import user from "../img/user.png";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import TitleComp from "../Component/TitleComp";
import { Button, Col, Modal } from "react-bootstrap";
import sold from "../img/sold.png";
import { RotatingLines } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

function UserDetail() {
  const [data, setdata] = useState([]);
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e, item) => {
    setmodaldata(item)
    console.log(item)
    setShow(true)
  };
  let token = localStorage.getItem("token");
  const [historydata, sethistorydata] = useState([]);
  const [modaldata, setmodaldata] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const [filterverified, setfilterverified] = useState("");
  const [searchshow, setsearchshow] = useState(false)

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [showFullBio, setShowFullBio] = useState(false);

  const toggleReadMore = () => {
    setShowFullBio(!showFullBio);
  };

  const displayText = showFullBio
    ? data?.biography
    : data?.biography?.slice(0, 50);

  const handlehistory = () => {
    axios
      .get(`${BASE_URL}/membership_history?user=${id}`, {
        headers,
      })
      .then((res) => {
        sethistorydata(res.data.data);
        console.log("histori", res.data.data);
      });
  };
  const [page, setpage] = useState("1")
  const [rows, setrows] = useState("10")

  const getRowId = (row) => row._id;
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => {
        let name = params.row?.membership?.heading;

        return `${name}`;
      },
    },


    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.membership?.price;

        return `${limit}`;
      },
    },

    {
      field: "membership",
      headerName: "Membership",
      flex: 1,
      valueGetter: (params) => {
        let membershipcount = params.row?.membership?.membership_period_count;
        let membership = params.row?.membership?.membership_period;


        return `${membershipcount} ${membership}`;
      },
    },

    {
      field: "beat",
      headerName: "Beat Limit",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.membership?.no_of_beat_limit;

        return `${limit}`;
      },
    },
    {
      field: "membership_for",
      headerName: "Membership For",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.membership?.membership_for;

        return `${limit}`;
      },
    },
    {
      field: "beatlimit",
      headerName: "Upload Limit",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.membership?.upload_beat_limit;

        return `${limit}`;
      },
    },
    {
      field: "number",
      headerName: "Number of beat Limit",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.membership?.no_of_beat_limit;

        return `${limit}`;
      },
    },
    {
      field: "create",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) => {
        let limit = params.row?.created_at;

        return `${convertDateToMonthName(limit)}`;
      },
    },
    // {
    //   field: "purchaseon",
    //   headerName: "Purchase On",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     let unit = params.row.membership?.membership_period;
    //     let duration = params.row.membership?.membership_period_count;

    //     // Handle plural forms like "Years"
    //     unit = unit.toLowerCase().replace(/s$/, '');

    //     // Calculate the expiration date
    //     let expirationDate = new Date();
    //     if (unit === 'year') {
    //       expirationDate.setFullYear(expirationDate.getFullYear() - duration);
    //     } else if (unit === 'month') {
    //       expirationDate.setMonth(expirationDate.getMonth() - duration);
    //     } else if (unit === 'day') {
    //       expirationDate.setDate(expirationDate.getDate() - duration);
    //     }

    //     // Format the date as needed (you might want to use a library like moment.js for better formatting)
    //     let formattedDate = `${expirationDate.getFullYear()}-${expirationDate.getMonth() + 1}-${expirationDate.getDate()}`;

    //     return convertDateToMonthName(formattedDate);
    //   },
    // },



    // {
    //   field: "created at",
    //   headerName: "created at",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     let create = params.row?.membership?.created_at;
    //     let date = convertDateToMonthName(create);
    //     return date;
    //   },
    // },
  ];

  const handleuser = () => {
    axios
      .get(`${BASE_URL}/user?type=Admin&user_id=${id}`, { headers })
      .then((res) => {
        console.log(res.data);
        setdata(res.data.data);
      });
  };

  useEffect(() => {
    if (id) {
      handleuser();
      handlehistory();
    }
  }, [id]);

  const [verifiedcheck, setverifiedcheck] = useState("");
  const [beatdata, setbeatdata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate('')

  const fetchbeats = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/beat?seller=${id}&page=${page}&rows=${rows}`, { headers }).then((res) => {
      setbeatdata(res.data.data);
      console.log("response", res);
      if (res.data.message == "Ok") {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchbeats()
  }, [page])

  const fetchbueyrebeats = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/beat?buyer=${id}`, { headers }).then((res) => {
      setbeatdata(res.data.data);
      console.log("response", res);
      if (res.data.message == "Ok") {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (data?.type == "Buyer") {
      fetchbueyrebeats();
    } else {
      fetchbeats();
    }
  }, [data]);

  const handleAlert = (e, item) => {
    setLoading(true);
    let formdata = {
      is_verified: e.target.checked ? "Approved" : "Pending",
      _id: item._id,
    };
    axios.put(`${BASE_URL}/beat`, formdata, { headers }).then((res) => {
      console.log(res.data);
      fetchbeats();
    });
  };

  const handleReject = (e, item) => {
    setLoading(true);
    let formdata = {
      is_verified: "Rejected",
      _id: item._id,
    };
    axios.put(`${BASE_URL}/beat`, formdata, { headers }).then((res) => {
      console.log(res.data);
      fetchbeats();
    });
  };

  const handleFilter = () => {
    let filteredByName = "";

    if (searchQuery) {
      // Filter by beat name
      if (data.type == "Buyer") {
        filteredByName = beatdata?.filter((item) =>
          item.ordered_beat[0]?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      } else {
        filteredByName = beatdata?.filter((item) =>
          item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      // Filter by seller name
      const filteredBySeller = beatdata?.filter((item) =>
        item.seller?.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Combine the filtered results (if needed)
      const combinedFilteredData = [...filteredByName, ...filteredBySeller];

      // Update the state with the filtered data
      setfilterdata(combinedFilteredData);
    } else {
      // If searchQuery is empty, reset the data to the original state
      setfilterdata(beatdata);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [searchQuery, beatdata]);

  const handleFilterverified = (value) => {
    if (value) {
      let filtered = [];

      if (value === "All") {
        filtered = beatdata;
      } else {
        filtered = beatdata.filter((item) => {
          return item.is_verified === value;
        });
      }

      setfilterdata(filtered);
      console.log("filtered", filtered);
    }
  };


  const handlesearch = (e, name) => {
    if (name == "membership") {
      setsearchshow(false)
    } else {
      setsearchshow(true)
    }
  }


  const handleDelete = () => {
    let requestdata = {
      _id: data._id,
      phone: data.phone
    }
    axios.post(`${BASE_URL}/admin_delete_user`, requestdata, { headers }).then((res) => {
      console.log(res.data)
      if (res.data.error == "0") {
        toast.success(res.data.message)
        setTimeout(() => {
          navigate('/userlist')
        }, 500);
      }

    })
  }

  return (

    <Sidebaar
      content={
        <>
          <ToastContainer />
          {loading && (
            <div className="overlay">
              <RotatingLines
                strokeColor="#051322"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          <section className="mt-5">
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-md-8">
                  <div className="bg-white shadow p-3 text-center rounded-4 align-items-center">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-4">
                        <div
                          className=" p-3 text-white rounded-4"
                          style={{ background: "#87C4FF" }}
                        >
                          <img
                            src={
                              data?.image ? `${BASE_URL}/${data?.image}` : user
                            }
                            className="img-fluid rounded-circle"
                            style={{ height: "100px" }}
                            alt=""
                          />
                          <h3 className="text-blue mt-2 fw-bold">
                            {data?.name}
                          </h3>
                          {/* <h6 className="mb-0">
                            <span className="text-dark t-14 fw-bold">
                              Date of Birth
                            </span>
                          </h6>
                          <h6 className="text-white fw-bold">
                            {" "}
                            {convertDateToMonthName(data?.dob)}
                          </h6> */}
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="position-relative">
                          {!data.deleted_at && <div className="" style={{ position: "absolute", right: "0" }}>
                            <button onClick={(e) => handleDelete(e)} className="btn bg-danger text-white px-3"><i class="fa-solid fa-trash me-2"></i> Delete </button>
                          </div>}
                          <div className="text-start">
                            <p>
                              <span className="text-blue fw-bold">Email</span> :{" "}
                              <span>{data?.email}</span>
                            </p>
                            {!data.deleted_at && <p>
                              <span className="text-blue fw-bold">Phone</span> :{" "}
                              <span>{data?.phone}</span>
                            </p>}
                            {/* <p>
                            <span className="text-blue fw-bold"> Active</span> :
                            {data?.is_active ? (
                              <img
                                src={check}
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                            ) : (
                              <img
                                src={cancel}
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                            )}
                          </p> */}
                            <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Verified{" "}
                              </span>{" "}
                              :
                              {data?.is_verified ? (
                                <img
                                  src={check}
                                  className="img-fluid"
                                  style={{ height: "30px" }}
                                ></img>
                              ) : (
                                <img
                                  src={cancel}
                                  className="img-fluid"
                                  style={{ height: "30px" }}
                                ></img>
                              )}
                            </p>
                            <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Expired On :{" "}
                              </span>
                              <span className="text-danger fw-bold">
                                {convertDateToMonthName(
                                  data?.membership_expired_at
                                )}
                              </span>
                            </p>
                            {data.bank_acn && <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Back Account Number :{" "}
                              </span>
                              <span className="text-danger fw-bold">

                                {data?.bank_acn}

                              </span>
                            </p>}
                            {data.bank_ifsc && <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Back Ifsc :{" "}
                              </span>
                              <span className="text-danger fw-bold">

                                {data?.bank_ifsc}

                              </span>
                            </p>}
                            {data.bank_branch && <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Back Branch :{" "}
                              </span>
                              <span className="text-danger fw-bold">

                                {data?.bank_branch}

                              </span>
                            </p>}
                            {data.bank_upi && <p>
                              <span className="text-blue fw-bold">
                                {" "}
                                Back Upi :{" "}
                              </span>
                              <span className="text-danger fw-bold">

                                {data?.bank_upi}

                              </span>
                            </p>}
                            <p>
                              <span className="text-blue fw-bold"> Bio : </span>{" "}
                              {displayText}
                            </p>
                            {data?.biography?.length > 50 && (
                              <button className="btn bg-gradient text-white" onClick={toggleReadMore}>
                                {showFullBio ? "Read Less" : "Read More"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <ul
                    class="nav nav-pills mb-3 w-100"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={(e) => handlesearch(e, "membership")}
                      >
                        Membership History
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={(e) => handlesearch(e, "history")}
                      >
                        {data?.type == "Buyer"
                          ? "Purchase History"
                          : "Beat List"}

                      </button>
                    </li>
                  </ul>
                </div>

                {searchshow && <div className={data.type == "Buyer" ? "col-md-6" : "col-md-4"}>
                  <div className="">
                    <div className="input-group align-items-center bg-white shadow px-3 rounded-4 my-2">
                      <span>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </span>
                      <input
                        type="search"
                        className="form-control border-0 shadow-none"
                        placeholder="Search With Beat Name or Seller Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </div>}
                {searchshow && data.type != "Buyer" && (
                  <div className="col-md-2">
                    <select
                      name=""
                      className="form-control form-select"
                      id=""
                      onClick={(e) => handleFilterverified(e.target.value)}
                    >
                      <option value="All">All</option>
                      <option value="Approved">Approved</option>
                      <option value="Pending">Pending</option>
                      {/* <option value="Rejected">Rejected</option> */}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </section>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <section className="mt-3">
                <div className="container">
                  <div className="row">
                    {/* <Col md="12">
                      <TitleComp title="Membership History" />
                    </Col> */}
                    {historydata?.length > 0 ? (
                      <div className="col-md-12">
                        <Box sx={{ height: 500, width: "100%" }}>
                          <DataGrid
                            rows={historydata}
                            columns={columns}
                            pageSize={5}
                            getRowId={getRowId}
                            initialState={{
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                          />
                        </Box>
                      </div>
                    ) : (
                      <div className=" bg-white shadow p-3 text-center rounded-3">
                        <h5 className="fw-bold">
                          {" "}
                          Membership History Not Found
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              {data?.type == "Buyer" ? (
                <>
                  <section className="my-3">
                    <div className="container">
                      <div className="row gy-3">
                        {filterdata.length > 0 ? (
                          filterdata?.map((item) => {

                            if (
                              item?.ordered_beat &&
                              item.ordered_beat?.length > 0
                            ) {
                              return (
                                <Col md="4">
                                  <div
                                    className=" shadow p-3 rounded-3 position-relative"
                                    style={{ background: "#fef7f9" }}
                                  >
                                    <div className="" style={{ position: "absolute", top: "10px", left: "10px" }}>
                                      <Button variant="primary" onClick={(e) => handleShow(e, item)}>
                                        <i class="fa-solid fa-circle-info"></i>
                                      </Button>
                                    </div>
                                    {item?.ordered_beat?.is_sold && (
                                      <>
                                        <div className="soldabs">
                                          <img
                                            src={sold}
                                            className="img-fluid"
                                            alt=""
                                            style={{ width: "80px" }}
                                          />
                                        </div>
                                      </>
                                    )}


                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={`${BASE_URL}/${item?.ordered_beat[0]?.image}`}
                                        className="img-fluid rounded-2"
                                        style={{ height: "100px" }}
                                        alt=""
                                      />
                                      <div className="ps-3">
                                        <h3
                                          className="text-capitalize mb-0 popins text-blue fw-bold"
                                          style={{ fontSize: "22px" }}
                                        >
                                          {item?.ordered_beat[0]?.name}
                                        </h3>
                                        <h4 className="text-success fw-bold">
                                          ₹ {item?.ordered_beat[0]?.price}
                                        </h4>
                                        <div className="">
                                          <h5 className="t-16">
                                            Seller :
                                            <span className="t-16">
                                              {item?.seller?.name}
                                            </span>
                                          </h5>
                                        </div>

                                        <div className="">
                                          <h5 className="t-16">
                                            Price :
                                            <span className="t-16">
                                              {" "}
                                              {item?.ordered_beat[0]?.price}
                                            </span>
                                          </h5>
                                        </div>

                                        <div className="">
                                          <h5 className="t-16">
                                            Plays :
                                            <span className="t-16">
                                              {" "}
                                              {item?.ordered_beat[0]?.plays}
                                            </span>
                                          </h5>
                                        </div>

                                      </div>
                                    </div>

                                    <div className="embed-responsive embed-responsive-16by9 mt-3 text-center w-100">
                                      <audio
                                        className="embed-responsive-item w-100"
                                        controls
                                      >
                                        <source
                                          src={`${BASE_URL}/${item?.ordered_beat[0]?.beat_file}`}
                                          type="audio/mp3"
                                        />
                                        Your browser does not support the audio
                                        tag.
                                      </audio>
                                    </div>
                                  </div>
                                </Col>
                              );
                            } else {

                              return null;
                            }
                          })
                        ) : (
                          <>
                            <div className=" bg-white shadow p-3 text-center rounded-3">
                              <h5 className="fw-bold">
                                {" "}
                                Purchase History Not Found
                              </h5>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  <section className="my-3">
                    <div className="container">
                      <div className="row gy-3">
                        {filterdata.length > 0 ? (
                          filterdata?.map((item) => {
                            if (item.is_verified !== "Rejected") {
                              return (
                                <Col md="4">
                                  <div
                                    className=" shadow p-3 rounded-3 position-relative"
                                    style={{ background: "#fef7f9" }}
                                  >
                                    <div className="" style={{ position: "absolute", top: "10px", left: "10px" }}>
                                      <Button variant="primary" onClick={(e) => handleShow(e, item)}>
                                        <i class="fa-solid fa-circle-info"></i>
                                      </Button>
                                    </div>
                                    {item?.is_sold && (
                                      <>
                                        <div className="soldabs">
                                          <img
                                            src={sold}
                                            className="img-fluid"
                                            alt=""
                                            style={{ width: "80px" }}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={`${BASE_URL}/${item?.image}`}
                                        className="img-fluid rounded-2"
                                        style={{ height: "100px" }}
                                        alt=""
                                      />
                                      <div className="ps-3">
                                        <h3
                                          className="text-capitalize mb-0 popins text-blue fw-bold"
                                          style={{ fontSize: "22px" }}
                                        >
                                          {item?.name}
                                        </h3>
                                        <h4 className="text-success fw-bold">
                                          ₹ {item?.price}
                                        </h4>
                                        <div className="">
                                          <h5 className="t-16">
                                            Seller :
                                            <span className="t-16">
                                              {item?.seller?.name}
                                            </span>
                                          </h5>
                                        </div>
                                        <div className="">
                                          <h5 className="t-16">
                                            Plays :
                                            <span className="t-16">
                                              {" "}
                                              {item?.plays}
                                            </span>
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                          <h5 className="t-16 mb-0">
                                            Approve Beat :
                                          </h5>
                                          <input
                                            type="checkbox"
                                            onClick={(e) =>
                                              handleAlert(e, item)
                                            }
                                            checked={
                                              item.is_verified == "Approved"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                        <button
                                          className={`btn text-white rounded-pill mt-3 px-3 ${item.is_verified == "Rejected"
                                            ? " bg-dark"
                                            : " bg-danger"
                                            }`}
                                          style={{ fontSize: "12px" }}
                                          onClick={(e) => handleReject(e, item)}
                                        >
                                          {item.is_verified == "Rejected"
                                            ? "Beat Rejected"
                                            : "Reject Beat"}
                                        </button>

                                      </div>
                                    </div>

                                    <div className="embed-responsive embed-responsive-16by9 mt-3 text-center w-100">
                                      <audio
                                        className="embed-responsive-item w-100"
                                        controls
                                      >
                                        <source
                                          src={`${BASE_URL}/${item?.beat_file}`}
                                          type="audio/mp3"
                                        />
                                        Your browser does not support the audio
                                        tag.
                                      </audio>
                                    </div>
                                  </div>
                                </Col>
                              );
                            }
                          })




                        ) : (
                          <>
                            <div className=" bg-white shadow p-3 text-center rounded-3">
                              <h5 className="fw-bold"> Beat List Not Found</h5>
                            </div>
                          </>
                        )}

                        <div className="d-flex align-items-center gap-3">
                          <button className="btn bg-primary" disabled={page == 1} onClick={(e) => setpage(parseInt(page) - parseInt(1))}><i class="fa-solid fa-angle-left text-white"></i></button>
                          <p className="mb-0">{page}</p>
                          <button className="btn bg-primary" disabled={beatdata.length < 10} onClick={(e) => setpage(parseInt(page) + parseInt(1))}><i class="fa-solid fa-chevron-right text-white"></i></button>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>





          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{modaldata?.name} Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold">Genre</h5>
                    </div>
                    <div className="col-md-6">
                      <h5 className="text-end"> {modaldata?.genre?.name}</h5>
                    </div>
                  </div>
                </div>
                {modaldata && modaldata?.dav_file?.map((item) => {
                  return (
                    <>
                      <div className="col-md-12">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <h5 className="fw-bold">Beat Stems</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="text-end">
                              <a href={`${BASE_URL}/${item}`} target="_blank">

                                <i class="fa-solid fa-music"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
                <div className="col-md-12">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold">DAW link</h5>
                    </div>
                    <div className="col-md-6">
                      <div className="text-end">
                        <a href={`${modaldata?.daw_link}`} target="_blank">

                          <i class="fa-solid fa-link"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        </>
      }
    />

  );
}

export default UserDetail;
