import React, { useEffect, useState } from 'react'
import Sidebaar from '../Component/Sidebaar'
import TitleComp from '../Component/TitleComp'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { BASE_URL } from '../BaseUrl'
import { RotatingLines } from 'react-loader-spinner'

function FormDetail() {
    let token = localStorage.getItem("token");
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);


    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleRowClick = (param) => {
        // console.log("Clicked row ID:", param.row._id);
        // navigate(`/userdetail/${param?.row?._id}`);
        // You can perform additional actions based on the clicked row ID
    };

    const handleallform = () => {
        axios.get(`${BASE_URL}/contact`, { headers }).then((res) => {
            console.log(res.data.data)
            setdata(res.data.data)
        })
    }


    useEffect(() => {
        handleallform()
    }, [])
    const getRowId = (row) => row._id;

    const columns = [
        { field: "name", headerName: "Name", flex: 1 },

        { field: "email", headerName: "Email", flex: 1 },

        { field: "phone", headerName: "Phone", flex: 1 },
        { field: "query", headerName: "query", flex: 1 },
        // { field: "address", headerName: "address", flex: 1 },

        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                const index = params.row.index;
                const id = params.row._id; // Corrected to rowIndex
                return (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            handleDelete(e, id);
                        }}
                    >
                        <i class="fa-solid fa-trash"></i>
                    </span>
                );
            },
        },
    ];


    const handleDelete = (e, id) => {
        setLoading(true)
        e.preventDefault()
        axios.get(`${BASE_URL}/delete_contact?_id=${id}`, { headers }).then((res) => {
            console.log(res)
            if (res.status == "200") {
                toast.success("Data Deleted Successfully")
                handleallform()
                setLoading(false)
            }

        }).catch((err) => {
            toast.error("Data Not Submit")
        }).finally(
            setLoading(false)
        )
    }
    return (
        <>
            {loading && (
                <div className="overlay">
                    <RotatingLines
                        strokeColor="#051322"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />
                </div>
            )}
            <ToastContainer />
            <Sidebaar content={
                <>
                    <section>
                        <div className="container">
                            <div className="row align-items-center">
                                <TitleComp title="Form Details" />

                                <div className="col-md-12">
                                    <Box sx={{ width: "100%" }}>
                                        <DataGrid
                                            rows={data ? data?.slice()?.reverse() : []}
                                            columns={columns}
                                            pageSize={5}
                                            onRowClick={handleRowClick}
                                            getRowId={getRowId}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[5, 10, 25]}
                                        />
                                    </Box>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </section>
                </>} />
        </>
    )
}

export default FormDetail
