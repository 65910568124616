import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Audio, CirclesWithBar, RotatingLines } from "react-loader-spinner";
import Sidebaar from "../Component/Sidebaar";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

function Genrelist() {
  let token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idindex, setidindex] = useState(null);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const userdata = () => {
    axios.get(`${BASE_URL}/genre`, { headers }).then((res) => {
      let dataArray = res.data.data;
      setdata(dataArray);
      console.log(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    userdata();
  }, []);

  const getRowId = (row) => row._id;
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => handleedit(e, index)}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </span>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index;
        const id = params.row._id; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleDelete(e, id);
              notify();
            }}
          >
            <i class="fa-solid fa-trash"></i>
          </span>
        );
      },
    },
  ];


  const notify = (message, type) => {
    toast(message, {
      type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      autoClose: 3000, // You can adjust the duration as needed
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const handleDelete = (e, id) => {
    e.preventDefault();

    let requestdata = {
      _id: id,
      deleted_at: 1,
    };

    axios
      .put(`${BASE_URL}/genre`, requestdata, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message === "Updated Successfully") {
          userdata();
          notify("Delete Successfully", "success"); // Show success toast notification
          console.log("Data", res.data);
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
        notify("Error deleting", "error"); // Show error toast notification
      });
  };

  const handleedit = (e, id) => {
    e.preventDefault();
    setidindex(id);

    setName(data[id].name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let requestdata = {
      name: name,
    };
    if (idindex) {
      requestdata._id = data[idindex]._id;
      axios.put(`${BASE_URL}/genre`, requestdata, { headers }).then((res) => {
        console.log(res);
        setLoading(false);
        userdata();
        setName("");
        setidindex(null);
      });
    } else {
      axios.post(`${BASE_URL}/genre`, requestdata, { headers }).then((res) => {
        console.log(res);
        setLoading(false);
        userdata();
        setName("");
      });
    }
  };

  return (
    <Sidebaar
      content={
        <>
          {loading && (
            <div className="overlay">
              <RotatingLines
                strokeColor="#051322"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          <section>
            <div className="container">
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  {loading && (
                    <div className="overlay">
                      <RotatingLines
                        strokeColor="#051322"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                  )}
                  <TitleComp
                    title={idindex != null ? "Edit Genre" : "Add Genre"}
                  />
                  <div className="col-md-4">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="mt-4">
                      <button className="btn bg-blue text-white">Submit</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <TitleComp title="Genre List" />
                <div className="col-md-12">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      //   rows={data} // Update this line
                      rows={data.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                      columns={columns}
                      pageSize={5}
                      getRowId={getRowId}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                    />
                  </Box>

                  <ToastContainer />
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}

export default Genrelist;
