import React, { useEffect, useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { Button, Col, Modal } from "react-bootstrap";
import TitleComp from "../Component/TitleComp";
import sold from "../img/sold.png";
import verified from "../img/check.png";
import cancel from "../img/cancel.png";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function Fetchbeats() {
  let userid = localStorage.getItem("userId");
  let token = localStorage.getItem("token");
  const [verifiedcheck, setverifiedcheck] = useState("");
  const [beatdata, setbeatdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [active, setactive] = useState(true);
  const [modaldata, setmodaldata] = useState([]);
  const [id, setid] = useState("")
  const [modelinfo, setmodelinfo] = useState([])
  const location = useLocation();
  const [rows, setrows] = useState("10")
  const [page, setpage] = useState("1")
  const [show, setShow] = useState(false);
  const [infomodal, setinfomodal] = useState(false)

  const handleClose = () => {
    setShow(false)
  };
  const handleinfoclose = () => {
    setinfomodal(false)
  };
  const handleinfo = (e, item) => {
    setmodelinfo(item)
    setinfomodal(true)
  }

  const handleShow = (e, id) => {
    setShow(true);

    setid(id)

    let matchedData = filterdata.filter((item) => id === item._id);

    console.log(matchedData);
    setmodaldata(matchedData[0]);
  };



  const soldpath = location.pathname == "/dash/sold";
  const pendingpath = location.pathname == "/dash/pending";
  const verifiedpath = location.pathname == "/dash/verified";
  const rejectedpath = location.pathname == "/dash/rejected";

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchbeats = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/beat?page=${page}&rows=${rows}`, { headers }).then((res) => {
      if (soldpath) {
        const filterData = res.data.data?.filter((beat) => {
          console.log("is_sold value:", beat?.is_sold);
          return beat?.is_sold === true;
        });
        setfilterdata(filterData);
        setbeatdata(filterData);
        console.log("filterdata", filterdata);
      } else {
        setbeatdata(res.data.data);
        console.log("response", res);
      }

      if (res.data.message == "Ok") {
        setLoading(false);
      }
    });
  };
  const [filterdata, setfilterdata] = useState([]);
  const handleFilter = () => {
    if (searchQuery) {
      const filterData = beatdata.filter((beat) => {
        const lowerCaseName = beat.name.toLowerCase();
        const lowerCaseVerified = beat.seller.name.toLowerCase();

        return (
          lowerCaseName.includes(searchQuery.toLowerCase()) ||
          lowerCaseVerified.includes(searchQuery.toLowerCase())
        );
      });
      setfilterdata(filterData);
    } else {
      setfilterdata(beatdata);
    }
  };

  useEffect(() => {
    fetchbeats()
  }, [page])

  // const handleFilter = () => {
  //   if (searchQuery) {
  //     const filterData = beatdata.filter((beat) => {
  //       const lowerCaseName = (beat.name || "").toLowerCase();
  //       const lowerCaseVerified = (beat.is_verified || "").toLowerCase();

  //       return (
  //         lowerCaseName.includes(searchQuery.toLowerCase()) ||
  //         lowerCaseVerified.includes(searchQuery.toLowerCase())
  //       );
  //     });
  //     setfilterdata(filterData);
  //   } else {
  //     setfilterdata(beatdata);
  //   }
  // };

  useEffect(() => {
    fetchbeats();
  }, []);

  useEffect(() => {
    handleFilter();
    if (pendingpath) {
      handleFilterverified("Pending");
    }
    if (verifiedpath) {
      handleFilterverified("Approved");
    }
  }, [searchQuery, beatdata, pendingpath, verifiedpath]);

  // const  = (e) => {
  //   e.preventDefault();
  // };

  const handleAlert = (e, item) => {
    setLoading(true);
    let formdata = {
      is_verified: e.target.checked ? "Approved" : "Pending",
      _id: item._id,
    };
    axios.put(`${BASE_URL}/beat`, formdata, { headers }).then((res) => {
      console.log(res.data);
      fetchbeats();
      setShow(false)
    });
  };



  const handleReject = (e, item) => {
    setLoading(true);
    let formdata = {
      is_verified: "Rejected",
      _id: item._id,
    };
    axios.put(`${BASE_URL}/beat`, formdata, { headers }).then((res) => {
      console.log(res.data);
      fetchbeats();
      setShow(false)
    });
  };

  const handleFilterverified = (value) => {
    console.log(value);

    if (value) {
      let filtered = [];

      if (value === "All") {
        filtered = beatdata;
      } else {
        filtered = beatdata.filter((item) => {
          return item.is_verified === value;
        });
      }

      setfilterdata(filtered);
      console.log("filtered", filtered);
    }
  };

  const getRowId = (row) => row._id;

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },

    {
      field: "image",
      headerName: "image",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`${BASE_URL}/${value}`}
            style={{
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            }}
            className="img-fluid tableimgmui"
            alt=""
          ></img>
        );
      },
    },

    { field: "price", headerName: "Price", flex: 1 },

    {
      field: "seller",
      headerName: "Seller",
      flex: 1,
      valueGetter: (params) => {
        return params.row.seller.name;
      },
    },

    {
      field: "info",
      headerName: "info",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index;
        const id = params.row._id; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => handleShow(e, id)}
          >
            <i class="fa-solid fa-circle-info fs-4"></i>
          </span>
        );
      },
    },

    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const index = params.row.index;
    //     const id = params.row._id; // Corrected to rowIndex
    //     return (
    //       <span
    //         style={{ cursor: "pointer" }}
    //         onClick={(e) => {
    //           handleDelete(e, id);
    //           notify();
    //         }}
    //       >
    //         <i class="fa-solid fa-trash"></i>
    //       </span>
    //     );
    //   },
    // },
  ];



  const onhandleActive = (e, name) => {
    e.preventDefault();
    if (name == "grid") {
      setactive(true);
    } else {
      setactive(false);
    }
  };

  return (
    <Sidebaar
      content={
        <>
          {loading && (
            <div className="overlay">
              <RotatingLines
                strokeColor="#051322"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}

          <section className="my-3">
            <div className="container">
              <div className="row gy-3 align-items-center">
                <TitleComp title="Beats List" />
                <div className="col-md-12">
                  <div className="text-end d-flex align-items-center justify-content-end gap-3">
                    <button
                      className={`btn border-0 ${active
                        ? "bg-gradient text-white"
                        : "bg-white text-dark shadow"
                        }`}
                      onClick={(e) => onhandleActive(e, "grid")}
                    >
                      <i class="fa-solid fa-table-cells"></i>
                    </button>
                    <button
                      className={`btn border-0 ${!active
                        ? "bg-gradient text-white"
                        : "bg-white text-dark shadow"
                        }`}
                      onClick={(e) => onhandleActive(e, "table")}
                    >
                      <i class="fa-solid fa-bars "></i>
                    </button>
                  </div>
                </div>

                {active && (
                  <>
                    <div
                      className={
                        rejectedpath || soldpath ? "col-md-9" : " col-md-6"
                      }
                    >
                      <div className="input-group align-items-center bg-white shadow px-3 rounded-4 my-4">
                        <span>
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </span>
                        <input
                          type="search"
                          className="form-control border-0 shadow-none"
                          placeholder="Search With Beat Name or Seller Name"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    {!rejectedpath && !soldpath && (
                      <div className="col-md-6">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button
                            className="btn bg-dark text-white"
                            onClick={(e) => handleFilterverified("Approved")}
                          >
                            Approved
                          </button>
                          <button
                            className="btn bg-dark text-white"
                            onClick={(e) => handleFilterverified("Pending")}
                          >
                            Pending
                          </button>
                          {/* <button
                      className="btn bg-dark text-white"
                      onClick={(e) => handleFilterverified("Rejected")}
                    >
                      Rejected
                    </button> */}
                          <button
                            className="btn bg-dark text-white"
                            onClick={(e) => handleFilterverified("All")}
                          >
                            All
                          </button>
                        </div>
                      </div>
                    )}
                    {filterdata.map((item) => {
                      if (
                        rejectedpath
                          ? item.is_verified === "Rejected"
                          : item.is_verified !== "Rejected"
                      ) {
                        return (
                          <>
                            <Col md="4">
                              <div
                                className=" shadow p-3 rounded-3 position-relative"
                                style={{ background: "#fef7f9" }}
                              >
                                {!rejectedpath && item.is_sold && (
                                  <>
                                    <div className="soldabs">
                                      <img
                                        src={sold}
                                        className="img-fluid"
                                        alt=""
                                        style={{ width: "80px" }}
                                      />
                                    </div>
                                  </>
                                )}
                                <div className="" style={{ position: "absolute", top: "10px", left: "10px" }}>
                                  <Button variant="primary" onClick={(e) => handleinfo(e, item)}>
                                    <i class="fa-solid fa-circle-info"></i>
                                  </Button>
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                  {item.image ? <img
                                    src={`${BASE_URL}/${item.image}`}
                                    className="img-fluid rounded-2"
                                    style={{ height: "100px", width: "100px", objectFit: "contain" }}
                                    alt=""
                                  /> : ""}
                                  <div className="ps-3">
                                    <h3
                                      className="text-capitalize mb-0 popins text-blue fw-bold"
                                      style={{ fontSize: "22px" }}
                                    >
                                      {item.name}
                                    </h3>
                                    <h4 className="text-success fw-bold">
                                      ₹ {item.price}
                                    </h4>
                                    <div className="">
                                      <h5 className="t-16">
                                        Seller :
                                        <span className="t-16">
                                          {item.seller.name}
                                        </span>
                                      </h5>
                                    </div>

                                    <div className="">
                                      <h5 className="t-16">
                                        Plays :
                                        <span className="t-16">
                                          {" "}
                                          {item.plays}
                                        </span>
                                      </h5>
                                    </div>
                                    {!rejectedpath && (
                                      <>
                                        <div className="d-flex align-items-center gap-2">
                                          <h5 className="t-16 mb-0">
                                            Approve Beat :
                                          </h5>
                                          <input
                                            type="checkbox"
                                            onClick={(e) =>
                                              handleAlert(e, item)
                                            }
                                            checked={
                                              item.is_verified == "Approved"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                        <button
                                          className={`btn text-white rounded-pill mt-3 px-3 ${item.is_verified == "Rejected"
                                            ? " bg-dark"
                                            : " bg-danger"
                                            }`}
                                          style={{ fontSize: "12px" }}
                                          onClick={(e) => handleReject(e, item)}
                                        >
                                          {item.is_verified == "Rejected"
                                            ? "Beat Rejected"
                                            : "Reject Beat"}
                                        </button>
                                      </>
                                    )}

                                    {/* <div className="d-flex align-items-center gap-2">
                                  <h5 className="t-16 mb-0">Status : </h5>
                                  <div className="">
                                    <img
                                      src={item.is_verified ? verified : cancel}
                                      className="img-fluid"
                                      alt=""
                                      style={{ width: "20px" }}
                                    />
                                  </div>
                                </div> */}
                                  </div>
                                </div>

                                <div className="embed-responsive embed-responsive-16by9 mt-3 text-center w-100">
                                  <audio
                                    className="embed-responsive-item w-100"
                                    controls
                                  >
                                    <source
                                      src={`${BASE_URL}/${item.beat_file}`}
                                      type="audio/mp3"
                                    />
                                    Your browser does not support the audio tag.
                                  </audio>
                                </div>
                              </div>
                            </Col>

                          </>
                        );
                      }
                    })}
                    {<div className="d-flex align-items-center gap-3">
                      <button className="btn bg-primary" disabled={page == 1} onClick={(e) => setpage(parseInt(page) - parseInt(1))}><i class="fa-solid fa-angle-left text-white"></i></button>
                      <p className="mb-0">{page}</p>
                      <button className="btn bg-primary" disabled={beatdata.length < 10} onClick={(e) => setpage(parseInt(page) + parseInt(1))}><i class="fa-solid fa-chevron-right text-white"></i></button>
                    </div>}
                  </>
                )}
              </div>
            </div>
          </section>

          {!active && (
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        rows={filterdata
                          .filter((row) => row.is_verified !== "Rejected") // Filter out "Rejected" rows
                          .map((row, index) => ({
                            ...row,
                            index,
                          }))}
                        columns={columns}
                        pageSize={5}
                        getRowId={getRowId}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </section>
          )}

          <Modal
            show={show}
            onHide={handleClose}
            className="modalbox"
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modaldata && (
                <div
                  className=" shadow p-3 rounded-3 position-relative"
                  style={{ background: "#fef7f9" }}
                >
                  {!rejectedpath && modaldata.is_sold && (
                    <>
                      <div className="soldabs">
                        <img
                          src={sold}
                          className="img-fluid"
                          alt=""
                          style={{ width: "80px" }}
                        />
                      </div>
                    </>
                  )}

                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={`${BASE_URL}/${modaldata.image}`}
                      className="img-fluid rounded-2"
                      style={{ height: "100px" }}
                      alt=""
                    />
                    <div className="ps-3">
                      <h3
                        className="text-capitalize  mb-0 popins text-blue fw-bold"
                        style={{ fontSize: "22px" }}
                      >
                        {modaldata.name}
                      </h3>
                      <h4 className="text-success fw-bold">
                        ₹ {modaldata.price}
                      </h4>
                      <div className="text-dark">
                        <h5 className="t-16">
                          Seller :
                          <span className="t-16">{modaldata.seller?.name}</span>
                        </h5>
                      </div>

                      <div className="text-dark">
                        <h5 className="t-16">
                          Plays :
                          <span className="t-16"> {modaldata.plays}</span>
                        </h5>
                      </div>
                      {!rejectedpath && (
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <h5 className="t-16 mb-0 text-dark">
                              Approve Beat :
                            </h5>
                            <input
                              type="checkbox"
                              onClick={(e) => handleAlert(e, modaldata)}
                              checked={
                                modaldata.is_verified == "Approved"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <button
                            className={`btn text-white rounded-pill mt-3 px-3 ${modaldata.is_verified == "Rejected"
                              ? " bg-dark"
                              : " bg-danger"
                              }`}
                            style={{ fontSize: "12px" }}
                            onClick={(e) => handleReject(e, modaldata)}
                          >
                            {modaldata.is_verified == "Rejected"
                              ? "Beat Rejected"
                              : "Reject Beat"}
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="embed-responsive embed-responsive-16by9 mt-3 text-center w-100">
                    <audio className="embed-responsive-item w-100" controls>
                      <source
                        src={`${BASE_URL}/${modaldata.beat_file}`}
                        type="audio/mp3"
                      />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                </div>
              )}
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </Modal>


          <Modal show={infomodal} onHide={handleinfoclose}>
            <Modal.Header closeButton>
              <Modal.Title>{modelinfo?.name} Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold">Genre</h5>
                    </div>
                    <div className="col-md-6">
                      <h5 className="text-end"> {modelinfo?.genre?.name}</h5>
                    </div>
                  </div>
                </div>
                {modelinfo && modelinfo?.dav_file?.map((item) => {
                  return (
                    <>
                      <div className="col-md-12">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <h5 className="fw-bold">Beat Stems</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="text-end">
                              <a href={`${BASE_URL}/${item}`} target="_blank">

                                <i class="fa-solid fa-music"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
                <div className="col-md-12">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold">DAW link</h5>
                    </div>
                    <div className="col-md-6">
                      <div className="text-end">
                        <a href={`${modelinfo?.daw_link}`} target="_blank">

                          <i class="fa-solid fa-file fs-3"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleinfoclose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        </>
      }
    />
  );
}

export default Fetchbeats;

// ...
