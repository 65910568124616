import React from "react";
import Sidebaar from "../Component/Sidebaar";
import Addmemebers from "../Element/Addmemebers";

function MembershipPage() {
  return (
    <>
      <Sidebaar content={<Addmemebers />} />
    </>
  );
}

export default MembershipPage;
