import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebaar from "../Component/Sidebaar";
import TitleComp from "../Component/TitleComp";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function TotalMembership() {
    const { state } = useLocation();
    const [data, setdata] = useState(state)

    console.log("State", state);

    const getRowId = (row) => row._id;

    const navigate = useNavigate()


    const columns = [
        {
            field: "heading", headerName: "Name", flex: 1, valueGetter: (params) => {
                return params.row.membership.heading
            }
        },


        {
            field: "membership_period_count",
            headerName: "Membership Period Count",
            flex: 1,
            valueGetter: (params) => {
                return params.row.membership.membership_period_count
            }
        },
        {
            field: "membership_period", headerName: "Membership Period", flex: 1, valueGetter: (params) => {
                return params.row.membership.membership_period
            }
        },
        {
            field: "membership", headerName: "Membership for", flex: 1, valueGetter: (params) => {
                return params.row.membership.membership_for
            }
        },

        {
            field: "beat limit", headerName: "Beat Limit", flex: 1, valueGetter: (params) => {
                return params.row.membership.no_of_beat_limit
            }
        },
        
        // {
        //   field: "image",
        //   headerName: "Image",
        //   flex: 1,
        //   renderCell: (params) => {
        //     const { value } = params;
        //     return (
        //       <img
        //         src={`${BASE_URL}/${value}`}
        //         style={{
        //           width: "45px",
        //           height: "45px",
        //           borderRadius: "50%",
        //         }}
        //         className="img-fluid tableimgmui"
        //         alt="Membership Image"
        //       />
        //     );
        //   },
        // },
        // {
        //     field: "edit",
        //     headerName: "Edit",
        //     flex: 1,
        //     renderCell: (params) => {
        //         const index = params.row.index;
        //         const id = params.row._id; // Corrected to rowIndex
        //         return (
        //             <span
        //                 style={{ cursor: "pointer" }}
        //                 onClick={(e) => navigate(`/editmemebership/${id}`)}
        //             >
        //                 <i className="fa-solid fa-pen-to-square"></i>
        //             </span>
        //         );
        //     },
        // },
        // {
        //     field: "delete",
        //     headerName: "Delete",
        //     flex: 1,
        //     renderCell: (params) => {
        //         const index = params.row.index;
        //         const id = params.row._id; // Corrected to rowIndex
        //         return (
        //             <span
        //                 style={{ cursor: "pointer" }}
        //                 onClick={(e) => {
        //                     handleDelete(e, id);
        //                     notify();
        //                 }}
        //             >
        //                 <i class="fa-solid fa-trash"></i>
        //             </span>
        //         );
        //     },
        // },
    ];
    return (
        <>
            <Sidebaar content={
                <>

                    <section>
                        <div className="container">
                            <div className="row">
                                <TitleComp title="Members History" />
                                <div className="col-md-12">
                                    <Box sx={{ height: 500, width: "100%" }}>
                                        <DataGrid
                                            //   rows={data} // Update this line
                                            rows={data.map((row, index) => ({
                                                ...row,
                                                index,
                                            }))}
                                            columns={columns}
                                            pageSize={5}
                                            getRowId={getRowId}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[5, 10, 25]}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </section>
                </>} />
        </>
    )
}

export default TotalMembership;
