import React, { useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import TitleComp from "../Component/TitleComp";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { RotatingLines } from "react-loader-spinner";

function Addpromo() {
  let token = localStorage.getItem("token");
  const [val, setval] = useState("");
  const [code, setcode] = useState("");
  const [file, setfile] = useState(null);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [loading, setloading] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleSubmit = (e) => {
    setloading(true);
    e.preventDefault();
    const startTimestamp = new Date(startdate).getTime() / 1000; // Convert milliseconds to seconds
    const endTimestamp = new Date(enddate).getTime() / 1000;
    const formdata = new FormData();
    formdata.append("val", val);
    formdata.append("code", code);
    formdata.append("image", file);
    formdata.append("started_at", startTimestamp);
    formdata.append("ended_at", endTimestamp);

    axios.post(`${BASE_URL}/promos`, formdata, { headers }).then((res) => {
      console.log(res);
      if (res.data != null) {
        setloading(false);
      }
    });
  };

  const handleFile = (e) => {
    const selectedfile = e.target.files[[0]];
    console.log(selectedfile);
    setfile(selectedfile);
  };

  return (
    <>
      <Sidebaar
        content={
          <>
            {loading && (
              <div className="overlay">
                <RotatingLines
                  strokeColor="#051322"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            )}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <TitleComp title="Add Promo" />
                  </div>
                  <div className="col-md-12">
                    <Form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row gy-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Code</label>
                            <input
                              type="text"
                              value={code}
                              onChange={(e) => setcode(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Value</label>
                            <input
                              type="number"
                              value={val}
                              onChange={(e) => setval(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">File</label>
                            <input
                              type="file"
                              onChange={(e) => handleFile(e)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Started At</label>
                            <input
                              type="date"
                              value={startdate}
                              onChange={(e) => setstartdate(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Ended At</label>
                            <input
                              type="date"
                              value={enddate}
                              onChange={(e) => setenddate(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mt-3">
                            <button className="btn bg-blue text-white">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
}

export default Addpromo;
