import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Audio, CirclesWithBar, RotatingLines } from "react-loader-spinner";
import convertDateToMonthName from "../Component/ConvertMonth";
import { NavItem } from "react-bootstrap";
import check from "../img/check.png";
import cancel from "../img/cancel.png";
import { ToastContainer, toast } from "react-toastify";
import { render } from "@testing-library/react";

function UserList() {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usertype, setusertype] = useState("buyer");

  const location = useLocation();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const sellerpath = location.pathname == `/dash/Totalseller`;


  const userdata = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/user?type=Admin`, { headers }).then((res) => {
      console.log(res.data.data);
      if (!sellerpath) {
        if (usertype == "buyer") {
          setdata(res.data.data.buyer);
          setLoading(false);
        } else {

          setdata(res.data.data.seller);
          setLoading(false);

        }
      } else {
        if (usertype == "seller") {
          setdata(res.data.data.seller);
          setLoading(false);
        }
      }

    });
  };

  useEffect(() => {
    userdata();
  }, []);
  const getRowId = (row) => row._id;
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },

    { field: "email", headerName: "Email", flex: 1 },
    // {
    //   field: "image",
    //   headerName: "image",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const { value } = params;
    //     return (
    //       <img
    //         src={`${BASE_URL}/${value}`}
    //         style={{
    //           width: "45px",
    //           height: "45px",
    //           borderRadius: "50%",
    //         }}
    //         className="img-fluid tableimgmui"
    //       ></img>
    //     );
    //   },
    // },
    { field: "phone", headerName: "Phone", flex: 1 },
    {
      field: "deleted_at",
      headerName: "Deleted User",
      flex: 1,
      renderCell: (params) => {
        const deletedAt = params.row.deleted_at;
        const isDeleted = deletedAt && deletedAt !== "";

        return (
          <span
            className={`px-2 py-1 rounded text-white ${isDeleted ? "bg-danger" : "bg-success"
              }`}
          >
            {isDeleted ? "Deleted" : "Active"}
          </span>
        );
      }
    }

    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const index = params.row.index;
    //     const id = params.row._id; // Corrected to rowIndex
    //     return (
    //       <span
    //         style={{ cursor: "pointer" }}
    //         onClick={(e) => {
    //           handleDelete(e, id);
    //           notify();
    //         }}
    //       >
    //         <i class="fa-solid fa-trash"></i>
    //       </span>
    //     );
    //   },
    // },
    // { field: "type", headerName: "Type", flex: 1 },
    // {
    //   field: "is_verified",
    //   headerName: "Is_Verified",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const { _id } = params.row; // Corrected this line
    //     return (
    //       <input
    //         type="checkbox"
    //         onClick={(e) => handleCheckboxChange(e, _id)}
    //         checked={params.row.is_verified}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const id = params.row._id;
    //     return (
    //       <button onClick={() => navigate(`/user/${id}`)} className="btn">
    //         <i class="fa-solid fa-pen-to-square"></i>
    //       </button>
    //     );
    //   },
    // },
  ];



  // const handleDelete = (e, id) => {
  //   e.preventDefault();

  //   let requestdata = {
  //     _id: id,
  //     deleted_at: 1,
  //   };

  //   axios
  //     .put(`${BASE_URL}/user`, requestdata, { headers })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.message === "Updated Successfully") {
  //         userdata();
  //         notify("Delete Successfully", "success"); // Show success toast notification
  //         console.log("Data", res.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting:", error);
  //       notify("Error deleting", "error"); // Show error toast notification
  //     });
  // };


  // const notify = (message, type) => {
  //   toast(message, {
  //     type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
  //     autoClose: 3000, // You can adjust the duration as needed
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // };

  const handleRowClick = (param) => {
    console.log("Clicked row ID:", param.row._id);
    navigate(`/userdetail/${param?.row?._id}`);
    // You can perform additional actions based on the clicked row ID
  };

  const handleCheckboxChange = (e, id) => {
    setLoading(true);
    let formdata = {
      is_verified: e.target.checked,
      _id: id,
    };
    axios.put(`${BASE_URL}/user`, formdata, { headers }).then((res) => {
      console.log(res.data);
      userdata();
    });
  };
  useEffect(() => {
    userdata();
  }, [usertype]);

  useEffect(() => {
    if (sellerpath) {
      setusertype("seller");
    }
  }, [sellerpath]);
  return (
    <>
      {loading && (
        <div className="overlay">
          <RotatingLines
            strokeColor="#051322"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <section>
        <div className="container">
          <div className="row align-items-center">
            <TitleComp title="Members List" />
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="">Select Type</label>
                <select
                  name=""
                  value={usertype}
                  onChange={(e) => setusertype(e.target.value)}
                  className="form-control form-select"
                  id=""
                >
                  <option value="" className="d-none">
                    Select Type{" "}
                  </option>
                  <option value="buyer">Buyer</option>
                  <option value="seller">Seller</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <Box sx={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={data} // Update this line
                  columns={columns}
                  pageSize={5}
                  onRowClick={handleRowClick}
                  getRowId={getRowId}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              </Box>
              <ToastContainer />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserList;
