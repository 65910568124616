import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Audio, CirclesWithBar, RotatingLines } from "react-loader-spinner";
import Sidebaar from "../Component/Sidebaar";
import { ToastContainer, toast } from "react-toastify";

function MemberList() {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const userdata = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/membership?type=All`, { headers }).then((res) => {
      let dataArray = res.data.data;
      setdata(dataArray);
      console.log(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    userdata();
  }, []);

  const getRowId = (row) => row._id;
  const columns = [
    { field: "heading", headerName: "Name", flex: 1 },
    { field: "membership_for", headerName: "Membership For", flex: 1 },
    { field: "membership_period", headerName: "Membership Period", flex: 1 },
    {
      field: "membership_period_count",
      headerName: "Membership Period Count",
      flex: 1,
    },
    // {
    //   field: "image",
    //   headerName: "Image",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const { value } = params;
    //     return (
    //       <img
    //         src={`${BASE_URL}/${value}`}
    //         style={{
    //           width: "45px",
    //           height: "45px",
    //           borderRadius: "50%",
    //         }}
    //         className="img-fluid tableimgmui"
    //         alt="Membership Image"
    //       />
    //     );
    //   },
    // },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index;
        const id = params.row._id; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => navigate(`/editmemebership/${id}`)}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </span>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index;
        const id = params.row._id; // Corrected to rowIndex
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleDelete(e, id);
              notify();
            }}
          >
            <i class="fa-solid fa-trash"></i>
          </span>
        );
      },
    },
  ];
  const notify = (message, type) => {
    toast(message, {
      type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      autoClose: 3000, // You can adjust the duration as needed
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();

    let requestdata = {
      _id: id,
      deleted_at: 1,
    };

    axios
      .put(`${BASE_URL}/membership`, requestdata, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message === "Updated Successfully") {
          userdata();
          notify("Delete Successfully", "success"); // Show success toast notification
          console.log("Data", res.data);
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
        notify("Error deleting", "error"); // Show error toast notification
      });
  };

  return (
    <Sidebaar
      content={
        <>
          {loading && (
            <div className="overlay">
              <RotatingLines
                strokeColor="#051322"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          <section>
            <div className="container">
              <div className="row">
                <TitleComp title="Membership List" />
                <div className="col-md-12">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      //   rows={data} // Update this line
                      rows={data.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                      columns={columns}
                      pageSize={5}
                      getRowId={getRowId}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                    />
                  </Box>
                </div>
                <ToastContainer />
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}

export default MemberList;
